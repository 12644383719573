import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const spacetradersApi = createApi({
    reducerPath: 'spacetradersApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.spacetraders.io/v2/' }),
    endpoints: (builder) => ({
        getStatus: builder.query({
            query: () => '',
        }),
    })
});

export const { useGetStatusQuery } = spacetradersApi;

import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function Register() {
  const [agentSymbol, setAgentSymbol] = useState('');

  function onSubmit(event) {
    event.preventDefault();
    console.log('submit');
  }

  return (
    <div>
      <h1>Register</h1>
      <form onSubmit={onSubmit} noValidate={false}>
        <TextField id="agentSymbol" label="Agent Symbol" variant="outlined" type="text" value={agentSymbol} onChange={({ target: { value } }) => setAgentSymbol(value)} required />
        <Button variant="contained" type="submit">Register</Button>
      </form>
    </div>
  );
}
import { useState } from 'react';

export default function Login() {
  const [value, setValue] = useState('');
  let input = "";
  function onChange(event) {
    setValue(event.target.value);
  }
  return (
    <div>
      <h1>Login</h1>
      <form>
        <h3>SpaceTraders Token</h3>
        <div>{value}</div>
        <input type="text" value={value} onChange={onChange} />
      </form>
    </div>
  );
}
